import { Group, Mesh, Vector3, VideoTexture } from 'three';

import { RESPONSIVE_VIDEO_MATERIAL } from '../_app/cuchillo/3D/materials/ResponsiveVideoMaterial';
import TexturesController from '../_app/cuchillo/3D/TexturesController';
import WebGLGroup from '../_app/cuchillo/3D/WebGLGroup';
import WebGLObject from '../_app/cuchillo/3D/WebGLObject';
import { PLANE_GEOMETRY } from './constants';
import { Functions } from '../_app/cuchillo/utils/Functions';

export const PYRAMID_SETTINGS = {
    angle: .3,
    renderAngle: false,
    face: 0
}

export default class Pyramid extends WebGLGroup {
    sides = [];
    videos = [];
    poster = [];
    sca = new Vector3();
    rotationGroup = new Group();

    constructor(opts = {}) {
        super(opts);

        this.dom = opts.dom;
        this.posters = opts.posters;
        this.videos = opts.videos;
        this.mask = opts.mask;

        this.add(this.rotationGroup);

        this.initSides();
        this.resize();
    }

    initSides() {
        for (let i = 0; i < 4; i++) {
            const side = new PyramidSide({
                dom: this.dom,
                poster: this.posters[i],
                video: this.videos[i],
                mask: this.mask,
                side: i,
                size: {
                    x: 1920,
                    y: 1080,
                },
                sizeMask: {
                    x: 1,
                    y: 1,
                },
            });

            this.sides.push(side);
            this.rotationGroup.add(side);
        }

        this.sca.set(0, 0, 0);
        this.scale.set(0, 0, 0);
    }

    update(scale = 1, rotation = 0) {
        super.update();

        const { x, y, width, height } = this.dom.getBoundingClientRect();

        const position = Functions.domPositionTo3D(x, y);
        this.pos.x = position.x + width * .5;
        this.pos.y = position.y - height * .5;

        this.rotationGroup.rotation.y = rotation;

        this.sides.map(side => side.update());
        this.scale.set(scale, scale, scale);
    }

    resize() {
        const { x, y, width, height } = this.dom.getBoundingClientRect();

        const position = Functions.domPositionTo3D(x, y);
        this.pos.x = this.position.x = position.x + width * .5;
        this.pos.y = this.position.y = position.y - height * .5;
        // this.pos.z = this.position.z = - width * .5;

        this.sides.map(side => side.resize());
    }
}

class PyramidSide extends WebGLObject {
    side = 0;
    angle = 0;
    canPlay = false;
    mask;
    video;
    videoTexture;
    poster;
    posterTexture;
    facePosition = new Group();
    faceRotation = new Group();
    pyramidRotation = new Group();
    sizeMask = new Vector3();

    constructor(opts = {}) {
        super(opts);

        this.geometry = PLANE_GEOMETRY.clone();
        this.material = RESPONSIVE_VIDEO_MATERIAL.clone();
        this.side = opts.side;
        this.poster = opts.poster;
        this.video = opts.video;
        this.mask = opts.mask;

        if (opts.sizeMask) {
            this.sizeMask.copy(opts.sizeMask);
        } else {
            this.sizeMask = this.size;
        }

        this.init();
    }

    init() {
        this.mesh = new Mesh(this.geometry, this.material);

        this.add(this.facePosition);
        this.facePosition.add(this.faceRotation);
        this.faceRotation.add(this.pyramidRotation);
        this.pyramidRotation.add(this.mesh);
        this.visible = true;

        this.loadTextures();
        this.resize();
    }

    loadTextures() {
        if (this.poster) {
            TexturesController.load({
                src: this.poster,
                call: (texture) => {
                    this.posterTexture = texture;

                    if (this.canPlay) return;
                    this.material.uniforms.texture1.value = texture;
                }
            });
        }

        if (this.mask) {
            TexturesController.load({
                src: this.mask,
                material: this.material.uniforms.mask,
                attribute: 'value'
            });
        }

        if (this.video) {
            this.videoPlayer = document.createElement('video');
            this.videoPlayer.src = this.video;
            this.videoPlayer.crossOrigin = 'anonymous';
            this.videoPlayer.loop = true;
            this.videoPlayer.muted = true;
            this.videoPlayer.playsInline = true;
            this.videoPlayer.autoplay = false;

            const _play = () => {
                this.canPlay = true;
                this.videoPlayer.removeEventListener('canplay', _play);
                this.videoTexture = new VideoTexture(this.videoPlayer);
                this.material.uniforms.texture1.value = this.videoTexture;
            }

            this.videoPlayer.addEventListener('canplay', _play);
            this.videoPlayer.play();
        }
    }

    calculateResolution(size) {
        const width = size.x;
        const height = size.y;

        const planeAspect = this.mesh.scale.y / this.mesh.scale.x;
        let a1;
        let a2;

        if (height / width > planeAspect) {
            const h = (width / height) * planeAspect;
            // Canvas more horizontal than image
            a1 = 1;
            a2 = h;
        } else {
            // Canvas more vertical than image
            a1 = height / width / planeAspect;
            a2 = 1;
        }

        return {
            x: a1,
            y: a2
        };
    }

    resize() {
        const { width, height } = this.dom.getBoundingClientRect();

        super.resize(width, height);

        const r = width / 2;
        const y = height / 2;
        const pyHeight = Math.sqrt(height * height - r * r);
        const angleX = Math.atan2(pyHeight / 3, r);

        this.angle = angleX;

        const positions = [
            { x: 0, z: r }, // Frente
            { x: r, z: 0 }, // Derecha
            { x: 0, z: -r }, // Atrás
            { x: -r, z: 0 } // Izquierda
        ];
        const rotations = [
            { x: -angleX, y: 0 }, // Frente
            { x: -angleX, y: Math.PI / 2 }, // Derecha
            { x: -angleX, y: Math.PI }, // Atrás
            { x: -angleX, y: -Math.PI / 2 } // Izquierda
        ];

        console.log(y, pyHeight);

        this.mesh.position.y = y;
        this.position.y = this.pos.y = -y;

        this.pyramidRotation.rotation.x = rotations[this.side].x;

        this.facePosition.position.z = positions[this.side].z;
        this.facePosition.position.x = positions[this.side].x;
        this.facePosition.position.y = pyHeight / 8;

        this.faceRotation.rotation.y = rotations[this.side].y;

        this.material.uniforms.resolution.value = this.calculateResolution(this.size);
        this.material.uniforms.resolutionMask.value = this.calculateResolution(this.sizeMask);

        this.material.uniforms.angle.value = PYRAMID_SETTINGS.angle;
    }
}
