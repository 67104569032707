import gsap, { Power1, Power2, Expo } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import Pyramid, { PYRAMID_SETTINGS } from '../3D/Pyramid';
import DistortedImage from '../_app/cuchillo/3D/DistortedImage';
import RepeatedImage from '../_app/cuchillo/3D/RepeatedImage';
import WebGLGroup from '../_app/cuchillo/3D/WebGLGroup';
import RenderTargetSketch from '../3D/RenderTargetSketch';
import { Functions } from '../_app/cuchillo/utils/Functions';
import { Maths } from '../_app/cuchillo/utils/Maths';
import ModelGLB from '../_app/cuchillo/3D/ModelGLB';
import { MATCAP_MATERIAL } from '../3D/constants';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { isMobile, isSmartphone } from '../_app/cuchillo/core/Basics';
import { Ease } from '../_app/cuchillo/utils/Ease';
import Header from '../layout/Header';
import { DISTORTION_SETTINGS } from '../_app/cuchillo/3D/materials/DistortedPixelMaterial';

class ScrollItem__BillboardHome extends VScroll_Item {
  pyramid;
  logo;
  pattern;
  lettering;
  // rotationGroup;
  shown = false;
  offsetZ = 0;
  offsetY = 0;

  _calls = {
    loop: () => this.loop()
  }

  _animation = {
    scale: 0,
    step: 0,
    face: 0,
    index: 0,
    indexOffset: 0,
    triangleSize: .2,
    opacity: 0,
    cols: 0,
    row: 0,
    randIndexes: [-1],
    z: 0,
    logoOpacity: 0,
    rotation: 0
  };

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.init3D();

    Header.directHide();

    this.onShow = () => {
      gsap.ticker.add(this._calls.loop);

      if (this.shown) return;
      this.show3D();
    };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  init3D() {
    DISTORTION_SETTINGS.relaxation = 0;

    const video2 = this.item.getAttribute('data-video-2');
    const video3 = this.item.getAttribute('data-video-3');
    const video4 = this.item.getAttribute('data-video-4');
    const poster2 = this.item.getAttribute('data-poster-2');
    const poster3 = this.item.getAttribute('data-poster-3');
    const poster4 = this.item.getAttribute('data-poster-4');

    // PYRAMID
    const pyramid = GetBy.selector('[data-3d-target]', this.item)[0];
    this.pyramid = new Pyramid({
      scaFactor: .068,
      dom: pyramid,
      posters: ['/assets/images/black.jpg', poster2, poster3, poster4],
      videos: ['', video2, video3, video4],
      mask: '/assets/images/mask.png'
    });

    RenderTargetSketch.group.add(this.pyramid);

    // AMNESIA IMAGE
    const logo = GetBy.selector('[data-3d-distorted]', this.item)[0];
    this.logo = new DistortedImage({
      dom: logo,
      diffuse: '/assets/images/amnesia05.png',
      size: {
        x: 1920,
        y: 1080,
      },
      modificator: .7
    });
    const rotZ = Maths.maxminRandom(1, .2);
    this.logo.rotation.z = this.logo.rot.z = rotZ;

    RenderTargetSketch.group.add(this.logo);

    // PATTERN
    const pattern = GetBy.selector('[data-3d-repeated]', this.item)[0];
    this.pattern = new RepeatedImage({
      dom: pattern,
      diffuse: '/assets/images/memories-word.png',
      diffuse2: '/assets/images/are-word.png',
      diffuse3: '/assets/images/overrated-word.png',
      diffuse4: '/assets/images/memories-are-overrated-word.png',
      size: {
        x: 507,
        y: 178
      },
      sizeFinal: {
        x: 1430,
        y: 197
      },
      zFactor: .071
    });

    RenderTargetSketch.group.add(this.pattern);

    this.letteringDom = GetBy.selector('[data-3d-lettering]', this.item)[0];
    gsap.set(this.letteringDom, { y: 0 });
    const modelName = '/assets/models/logo-amnesia-ok.glb';
    const matcap = '/assets/images/C09E5C_DAD2B9_654429_81582D.png';
    this.lettering = new ModelGLB({
      model: modelName,
      dom: this.letteringDom,
      cols: 22,
      matcap: matcap,
      material: MATCAP_MATERIAL.clone(),
      yFactor: .78
    });
    this.lettering.init().then(() => {
      RenderTargetSketch.group.add(this.lettering);
      this.resize();
    });
  }

  show3D() {
    this.shown = true;

    DISTORTION_SETTINGS.relaxation = .9;

    let visibleItems = 20;
    let rands = [];
    let cols = isMobile ? 3 : 5;
    let face = 0;
    let delay = 0;
    let width = window.innerWidth / cols;
    let height = this.pattern.opts.size.y / (this.pattern.opts.size.x / width);
    let rows = window.innerHeight / height;
    let maxElements = rows * cols;

    this.timeline = gsap.timeline({ repeat: 0, paused: true });
    this.timeline.set(this._animation, { cols, face, opacity: 1, scale: .1, randIndexes: new Array(visibleItems) });

    delay += 0;
    const timePyramid = 1.15;

    if (!isSmartphone) {
      face += 1;
      this.timeline.to(this._animation, { scale: .35, duration: timePyramid, ease: Ease.EASE_CUCHILLO_IN_OUT }, delay);
      this.timeline.to(this._animation, { rotation: -1 * Math.PI / 2 * face, duration: timePyramid, ease: Ease.EASE_CUCHILLO_IN_OUT }, delay);

      // MEMORIES     
      rands = Functions.arrayRandomRange(cols, maxElements - cols - 1, visibleItems);
      for (let i = 0; i < rands.length; i++) {
        gsap.delayedCall(delay, () => { this._animation.randIndexes[i] = rands[i] });
        delay += .05;
      }

      // ARE
      gsap.delayedCall(delay, () => {
        rands = Functions.arrayRandomRange(cols, maxElements - cols - 1, visibleItems);
      });

      this.timeline.set(this._animation, { step: 1, randIndexes: new Array(visibleItems) }, delay);

      face += 1;
      this.timeline.to(this._animation, { scale: .75, duration: timePyramid, ease: Ease.EASE_CUCHILLO_IN_OUT }, delay);
      this.timeline.to(this._animation, { rotation: -1 * Math.PI / 2 * face, duration: timePyramid, ease: Ease.EASE_CUCHILLO_IN_OUT }, delay);

      for (let i = 0; i < rands.length; i++) {
        gsap.delayedCall(delay, () => { this._animation.randIndexes[i] = rands[i] });
        delay += .05;
      }

      // OVERRRATED
      gsap.delayedCall(delay, () => {
        rands = Functions.arrayRandomRange(cols, maxElements - cols - 1, visibleItems);
      });

      delay += .1;
      this.timeline.set(this._animation, { step: 2, randIndexes: new Array(visibleItems) }, delay);

      delay += .1;
      face += 1;
      this.timeline.to(this._animation, { scale: 1, duration: timePyramid, ease: Ease.EASE_CUCHILLO_IN_OUT }, delay);
      this.timeline.to(this._animation, { rotation: -1 * Math.PI / 2 * face, duration: timePyramid, ease: Ease.EASE_CUCHILLO_IN_OUT }, delay);

      for (let i = 0; i < rands.length; i++) {
        gsap.delayedCall(delay, () => { this._animation.randIndexes[i] = rands[i] });
        delay += .05;
      }
    } else {
      delay += 1;
      face = 3;
      this.timeline.to(this._animation, { scale: 1, duration: timePyramid, ease: Ease.EASE_CUCHILLO_IN_OUT }, delay);
      this.timeline.to(this._animation, { rotation: -1 * Math.PI / 2 * face, duration: timePyramid, ease: Ease.EASE_CUCHILLO_IN_OUT }, delay);
    }

    // MEMORIES FINAL
    // Calculate proportions with new columns
    const newCols = isMobile ? 2 : 3;

    // Set stuff
    gsap.delayedCall(delay, () => {
      if (isMobile) {
        rands = [1, 2, 3, 4, 5, 8, 11, 12, 14, 15, 17, 19, 22, 23, 24];
      } else {
        rands = [2, 3, 4, 9, 10, 14, 16, 17, 18];
      }
    });

    delay += .1;
    this.timeline.set(this._animation, {
      step: 3,
      cols: newCols,
      randIndexes: new Array(visibleItems)
    }, delay);

    delay += .1;
    this.timeline.to(this.letteringDom, { y: '-75%', duration: 1.5, ease: Ease.EASE_CUCHILLO_IN_OUT }, delay);
    gsap.delayedCall(delay + .5, () => {
      Header.show();
    });

    gsap.delayedCall(delay, () => {
      this._animation.randIndexes = rands;
    });

    gsap.delayedCall(delay + .2, () => {
      this._animation.logoOpacity = 1;
    });

    gsap.delayedCall(delay + .4, () => {
      this.item.classList.remove('--hide-sticker');
    });

    this.timeline.play();
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  loop() {
    this.logo.update(this._animation.logoOpacity);
    this.pyramid.update(this._animation.scale, this._animation.rotation);
    this.pattern.update(this._animation);

    const { x, y, width, height } = this.letteringDom.getBoundingClientRect();
    const position = Functions.domPositionTo3D(x, y);
    this.lettering.pos.x = (position.x + width * .5);
    this.lettering.pos.y = (position.y - height * .5) - this.offsetY * (.5 - this.progress);
    this.lettering.pos.z = this.offsetZ * this.progress * .5;
    this.lettering.rot.x = -this.progress * .85;

    this.lettering.update();
  }

  resize(__w, __h) {
    super.resize(__w, __h);

    this.offsetZ = Metrics.parseSize('1000fpx');
    this.offsetY = Metrics.parseSize('400fpx');

    if (isSmartphone && !Metrics.RESIZE_MOBILE) return;

    this.logo.resize();
    this.pattern.resize();
    this.pyramid.resize();
    this.lettering.resize();
  }

  dispose() {
    super.dispose();
    gsap.ticker.remove(this._calls.loop);
    RenderTargetSketch.group.remove(this.logo);
    RenderTargetSketch.group.remove(this.pattern);
    RenderTargetSketch.group.remove(this.lettering);
    this.logo.dispose();
    this.pattern.dispose();
    this.lettering.dispose();
  }
}

Scroll._registerClass('billboard-home', ScrollItem__BillboardHome);


// show3D() {
//   this.shown = true;

//   const cols = isMobile ? 3 : 5;
//   let face = 0;
//   let delay = 0;
//   let width = window.innerWidth / cols;
//   let height = this.pattern.opts.size.y / (this.pattern.opts.size.x / width);
//   let rows = window.innerHeight / height;
//   let maxElements = rows * cols;

//   this.timeline = gsap.timeline({ repeat: 0, paused: true });
//   this.timeline.set(this._animation, { delay: .05, step: 0, cols, face, row: 0, indexOffset: 0, opacity: 1, scale: 0 });

//   if (!isSmartphone) {
//     // MEMORIES     
//     // Even / Odd word this._animation
//     for (let i = 1; i < (rows * 2); i++) {
//       this.timeline.set(this._animation, { delay: .1, indexOffset: i });
//     }

//     face += 1;
//     this.timeline.to(this._animation, { scale: .2, face, duration: (rows * 2) * .1, ease: Expo.easeInOut }, delay);
//     delay += (rows * 2) * .1;

//     // ARE
//     // Row + desplazamiento this._animation
//     this.timeline.set(this._animation, { delay: .05, step: 1, indexOffset: 0 });
//     for (let i = 0; i < (rows * 2); i++) {
//       this.timeline.set(this._animation, { delay: .1, indexOffset: i });
//     }

//     face += 1;
//     this.timeline.to(this._animation, { scale: .4, face, duration: (rows * 2) * .1, ease: Expo.easeInOut }, delay);
//     delay += (rows * 2) * .1;

//     // OVERRRATED
//     // Row index
//     this.timeline.set(this._animation, { delay: .05, step: 2, indexOffset: 0 });

//     for (let i = 0; i < rows; i++) {
//       this.timeline.set(this._animation, { delay: .1, row: i });
//     }
//     for (let i = 0; i < rows; i++) {
//       this.timeline.set(this._animation, { delay: .1, row: i });
//     }

//     face += 1;
//     this.timeline.to(this._animation, { scale: .6, face, duration: (rows * 2) * .1, ease: Expo.easeInOut }, delay);
//     delay += (rows * 2) * .1;

//     // MEMORIES ARE OVERRRATED
//     // Flash
//     this.timeline.set(this._animation, { delay: .05, step: 3, indexOffset: 0, cols: 1 });

//     let opacity = 1;
//     for (let i = 0; i < (rows * 2); i++) {
//       this.timeline.set(this._animation, { delay: .1, opacity });
//       opacity = !opacity;
//     }

//     face += 1;
//     this.timeline.to(this._animation, { scale: .8, face, duration: (rows * 2) * .1, ease: Expo.easeInOut }, delay);
//     delay += (rows * 2) * .1;
//   } else {
//     face += 1;
//   }

//   // MEMORIES FINAL
//   // Calculate proportions with new columns
//   const newCols = isMobile ? 2 : 3;
//   width = window.innerWidth / newCols;
//   height = this.pattern.opts.size.y / (this.pattern.opts.size.x / width);
//   rows = Math.floor(window.innerHeight / height);
//   maxElements = rows * newCols;

//   // Set stuff
//   const visibleItems = isMobile ? 9 : 6;
//   const indexes = Functions.arrayRandomRange(newCols, maxElements - newCols - 1, visibleItems);
//   this.timeline.set(this._animation, { delay: .05, step: 4, cols: newCols, opacity: 1, randIndexes: indexes });

//   face += 1;
//   this.timeline.to(this._animation, { scale: 1, face, duration: (rows * 2) * .1, ease: Expo.easeInOut }, delay);

//   this.timeline.play();
// }