import { LinearFilter, RepeatWrapping, Vector3 } from 'three';

import WebGLObject from './WebGLObject';
import TexturesController from './TexturesController';
import { PLANE_GEOMETRY } from '../../../3D/constants';
import { REPEATED_IMAGE_MATERIAL } from './materials/RepeatedImageMaterial';
import { isMobile } from '../core/Basics';

export default class DistortedImage extends WebGLObject {
    diffuse;
    diffuse2;
    diffuse3;
    diffuse4;
    sizeFinal = new Vector3();
    mobile = false;

    constructor(opts = {}) {
        super(opts);

        this.geometry = PLANE_GEOMETRY;
        this.material = REPEATED_IMAGE_MATERIAL.clone();
        this.diffuse = opts.diffuse;
        this.diffuse2 = opts.diffuse2;
        this.diffuse3 = opts.diffuse3;
        this.diffuse4 = opts.diffuse4;
        this.diffuseAlt = opts.diffuseAlt;
        this.mobile = isMobile;

        this.sizeFinal.copy(opts.sizeFinal);

        this.init();
    }

    init() {
        super.init();
        this.loadTexture();
        this.resize();

        this.material.uniforms.mobile.value = this.mobile;
    }

    loadTexture() {
        if (this.diffuse) {
            TexturesController.load({
                src: this.diffuse,
                material: this.material.uniforms.texture1,
                attribute: 'value',
                call: () => {
                    this.material.uniforms.texture1.wrapS = RepeatWrapping;
                    this.material.uniforms.texture1.wrapT = RepeatWrapping;
                    this.material.uniforms.texture1.minFilter = LinearFilter;
                    this.material.uniforms.texture1.magFilter = LinearFilter;
                }
            });
        }

        if (this.diffuse2) {
            TexturesController.load({
                src: this.diffuse2,
                material: this.material.uniforms.texture2,
                attribute: 'value',
                call: () => {
                    this.material.uniforms.texture2.wrapS = RepeatWrapping;
                    this.material.uniforms.texture2.wrapT = RepeatWrapping;
                    this.material.uniforms.texture2.minFilter = LinearFilter;
                    this.material.uniforms.texture2.magFilter = LinearFilter;
                }
            });
        }

        if (this.diffuse3) {
            TexturesController.load({
                src: this.diffuse3,
                material: this.material.uniforms.texture3,
                attribute: 'value',
                call: () => {
                    this.material.uniforms.texture3.wrapS = RepeatWrapping;
                    this.material.uniforms.texture3.wrapT = RepeatWrapping;
                    this.material.uniforms.texture3.minFilter = LinearFilter;
                    this.material.uniforms.texture3.magFilter = LinearFilter;
                }
            });
        }

        // if (this.diffuse4) {
        //     TexturesController.load({
        //         src: this.diffuse4,
        //         material: this.material.uniforms.texture4,
        //         attribute: 'value',
        //         call: () => {
        //             this.material.uniforms.texture4.wrapS = RepeatWrapping;
        //             this.material.uniforms.texture4.wrapT = RepeatWrapping;
        //             this.material.uniforms.texture4.minFilter = LinearFilter;
        //             this.material.uniforms.texture4.magFilter = LinearFilter;
        //         }
        //     });
        // }
    }

    update(animation) {
        super.update();

        const { x, y, width, height } = this.dom.getBoundingClientRect();

        const position = this.domPositionTo3D(x, y);
        this.pos.x = position.x + width * .5;
        this.pos.y = position.y - height * .5;
        this.pos.z = animation.z;

        this.material.uniforms.cols.value = animation.cols;
        // this.material.uniforms.showIndex.value = animation.index;
        // this.material.uniforms.showRowIndex.value = animation.row;
        // this.material.uniforms.showIndexOffset.value = animation.indexOffset;
        this.material.uniforms.step.value = animation.step;
        this.material.uniforms.opacity.value = animation.opacity;
        this.material.uniforms.highlightedIndexes.value = animation.randIndexes;
    }

    calculateResolution(size) {
        const width = size.x;
        const height = size.y;

        const planeAspect = this.mesh.scale.y / this.mesh.scale.x;
        let a1;
        let a2;

        if (height / width > planeAspect) {
            const h = (width / height) * planeAspect;
            // Canvas more horizontal than image
            a1 = 1;
            a2 = h;
        } else {
            // Canvas more vertical than image
            a1 = height / width / planeAspect;
            a2 = 1;
        }

        return {
            x: a1,
            y: a2
        };
    }

    resize() {
        const { x, y, width, height } = this.dom.getBoundingClientRect();
        const position = this.domPositionTo3D(x, y);

        this.position.x =
            this.pos.x = position.x + width * .5;
        this.position.y =
            this.pos.y = position.y - height * .5;

        super.resize(width, height);

        this.material.uniforms.resolution.value = this.calculateResolution(this.size);
        // this.material.uniforms.resolutionFinal.value = this.calculateResolution(this.sizeFinal);
    }
}
