import { FrontSide, ShaderMaterial } from 'three';
import CanvasColorTexture from '../textures/CanvasColorTexture';

export const REPEATED_IMAGE_VERTEX = `
    varying vec2 vUv;
    uniform float time;

    void main() {
        vUv = uv;

        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`;

export const REPEATED_IMAGE_FRAGMENT = `
    precision mediump float;

    #define PI 3.1415926538

    uniform float cols;
    uniform float opacity;
    // uniform float showIndex;
    // uniform float showIndexOffset;
    // uniform float showRowIndex;
    uniform vec2 resolution;
    uniform float step;
    uniform bool mobile;

    uniform float highlightedIndexes[50];

    // Texture
    uniform sampler2D texture1;
    uniform sampler2D texture2;
    uniform sampler2D texture3;
    uniform float imageScale;

    // Color
    uniform vec3 colorBG;

    varying vec2 vUv;

    float scaleCenter = 0.5;

    bool isHiddenIndex(float index) {
        for (int i = 0; i < 50; i++) {
            if (highlightedIndexes[i] == index) {
                return true;
            }
        }

        return false;
    }

    void main() {
        // Ajusta las coordenadas UV para repetir la imagen como un patrón
        vec2 repeatUv = vec2(vUv.x * cols, vUv.y * cols * (resolution.y / resolution.x));

        // Calcula el índice de tile basado en las coordenadas UV
        vec2 tileIndex = floor(repeatUv);
        float colIndex = mod(tileIndex.x, cols); // Índice de columna
        float rowIndex = mod(tileIndex.y, ceil(resolution.y / (resolution.x / cols))); // Índice de fila
        float index = colIndex + rowIndex * cols; // Índice único basado en columna y fila

        // Número máximo de elementos en la pantalla
        float maxCols = cols;
        float maxRows = ceil(resolution.y / (resolution.x / cols));
        float maxElements = maxCols * maxRows;
        float index0 = maxElements - index;

        // Aplica la función fract para obtener solo la parte fraccional de las coordenadas UV
        vec2 patternUv = fract(repeatUv);

        // Ajusta las coordenadas de textura según la escala de imagen
        vec2 textureUVScale = (patternUv - scaleCenter) * imageScale + scaleCenter;

        // Gets texture pixel color
        vec4 textureColor = texture2D(texture1, textureUVScale);
        vec4 textureColor2 = texture2D(texture2, textureUVScale);
        vec4 textureColor3 = texture2D(texture3, textureUVScale);

        vec4 finalTexture;
        vec4 finalBG = vec4(colorBG, 1.);

        if (isHiddenIndex(index0)) {
            discard;
        } else {
            if (step == 0.) {
                finalTexture = vec4(textureColor.rgb, textureColor.a * opacity);
            } else if (step == 1.) {
                finalTexture = vec4(textureColor2.rgb, textureColor2.a * opacity);
            } else if (step == 2.) {
                finalTexture = vec4(textureColor3.rgb, textureColor3.a * opacity);
            } else if (step == 3.) {
                finalTexture = vec4(textureColor.rgb, textureColor.a * opacity);
            } else if (step == 4.) {
                if (mobile) {
                    if (mod(rowIndex, 3.) == 0.) {
                        finalTexture = vec4(textureColor3.rgb, textureColor3.a * opacity);
                    } else if (mod(rowIndex, 3.) == 1.) {
                        finalTexture = vec4(textureColor2.rgb, textureColor2.a * opacity);
                    } else if (mod(rowIndex, 3.) == 2.) {
                        finalTexture = vec4(textureColor.rgb, textureColor.a * opacity);
                    }
                } else {
                    if (colIndex == 0.) {
                        finalTexture = vec4(textureColor.rgb, textureColor.a * opacity);
                    } else if (colIndex == 1.) {
                        finalTexture = vec4(textureColor2.rgb, textureColor2.a * opacity);
                    } else if (colIndex == 2.) {
                        finalTexture = vec4(textureColor3.rgb, textureColor3.a * opacity);
                    }
                }
            }
        }

        if (finalTexture.a == 0.) { discard; }

        gl_FragColor = finalTexture;
    }
`;

export const REPEATED_IMAGE_SETTINGS = {
    cols: 5,
    imageScale: 1,
    alt: false,
    colorBG: { r: 0, g: 0, b: 0 },
}

export const REPEATED_IMAGE_UNIFORMS = {
    texture1: { type: 't', value: new CanvasColorTexture().texture },
    texture2: { type: 't', value: new CanvasColorTexture().texture },
    texture3: { type: 't', value: new CanvasColorTexture().texture },
    opacity: { type: 'f', value: 1 },
    imageScale: { type: 'f', value: REPEATED_IMAGE_SETTINGS.imageScale },
    highlightedIndexes: { type: 'fv1', value: [0] }, // Array de índices resaltados,
    resolution: {
        type: 'v2',
        value: { x: 1, y: 1 }
    },
    cols: { type: 'f', value: REPEATED_IMAGE_SETTINGS.cols },
    step: { type: 'f', value: 0 },
    mobile: { type: 'bool', value: false },
    colorBG: { type: 'v3', value: REPEATED_IMAGE_SETTINGS.colorBG }
};

export default class RepeatedImageMaterial extends ShaderMaterial {
    constructor() {
        super({
            uniforms: REPEATED_IMAGE_UNIFORMS,
            fragmentShader: REPEATED_IMAGE_FRAGMENT,
            vertexShader: REPEATED_IMAGE_VERTEX,
            transparent: true,
            side: FrontSide
        });
    }
}

export const REPEATED_IMAGE_MATERIAL = new RepeatedImageMaterial();

// export const REPEATED_IMAGE_SETTINGS = {
//     cols: 5,
//     imageScale: 1,
//     showIndex: 0,
//     showIndexOffset: 0,
//     alt: false,
//     colorBG: { r: 0, g: 0, b: 0 },
// }

// export const REPEATED_IMAGE_UNIFORMS = {
//     texture1: { type: 't', value: new CanvasColorTexture().texture },
//     texture2: { type: 't', value: new CanvasColorTexture().texture },
//     texture3: { type: 't', value: new CanvasColorTexture().texture },
//     texture4: { type: 't', value: new CanvasColorTexture().texture },
//     texture1Alt: { type: 't', value: new CanvasColorTexture().texture },
//     opacity: { type: 'f', value: 1 },
//     imageScale: { type: 'f', value: REPEATED_IMAGE_SETTINGS.imageScale },
//     showIndex: { type: 'f', value: REPEATED_IMAGE_SETTINGS.showIndex },
//     showIndexOffset: { type: 'f', value: REPEATED_IMAGE_SETTINGS.showIndexOffset },
//     showRowIndex: { type: 'f', value: 0 },
//     highlightedIndexes: { type: 'fv1', value: [0] }, // Array de índices resaltados,
//     resolution: {
//         type: 'v2',
//         value: { x: 1, y: 1 }
//     },
//     resolutionFinal: {
//         type: 'v2',
//         value: { x: 1, y: 1 }
//     },
//     alt: { type: 'bool', value: REPEATED_IMAGE_SETTINGS.alt },
//     cols: { type: 'f', value: REPEATED_IMAGE_SETTINGS.cols },
//     step: { type: 'f', value: 0 },
//     colorBG: { type: 'v3', value: REPEATED_IMAGE_SETTINGS.colorBG }
// };

// export const REPEATED_IMAGE_UNIFORMS = {
//     texture1: { type: 't', value: new CanvasColorTexture().texture },
//     texture2: { type: 't', value: new CanvasColorTexture().texture },
//     texture3: { type: 't', value: new CanvasColorTexture().texture },
//     texture4: { type: 't', value: new CanvasColorTexture().texture },
//     texture1Alt: { type: 't', value: new CanvasColorTexture().texture },
//     opacity: { type: 'f', value: 1 },
//     imageScale: { type: 'f', value: REPEATED_IMAGE_SETTINGS.imageScale },
//     showIndex: { type: 'f', value: REPEATED_IMAGE_SETTINGS.showIndex },
//     showIndexOffset: { type: 'f', value: REPEATED_IMAGE_SETTINGS.showIndexOffset },
//     showRowIndex: { type: 'f', value: 0 },
//     highlightedIndexes: { type: 'fv1', value: [0] }, // Array de índices resaltados,
//     resolution: {
//         type: 'v2',
//         value: { x: 1, y: 1 }
//     },
//     resolutionFinal: {
//         type: 'v2',
//         value: { x: 1, y: 1 }
//     },
//     alt: { type: 'bool', value: REPEATED_IMAGE_SETTINGS.alt },
//     cols: { type: 'f', value: REPEATED_IMAGE_SETTINGS.cols },
//     step: { type: 'f', value: 0 },
//     colorBG: { type: 'v3', value: REPEATED_IMAGE_SETTINGS.colorBG }
// };


// export const REPEATED_IMAGE_FRAGMENT = `
//     precision mediump float;

//     #define PI 3.1415926538

//     uniform float cols;
//     uniform float opacity;
//     uniform float showIndex;
//     uniform float showIndexOffset;
//     uniform float showRowIndex;
//     uniform vec2 resolution;
//     uniform vec2 resolutionFinal;
//     uniform float step;
//     uniform bool alt;

//     uniform float highlightedIndexes[8];

//     // Texture
//     uniform sampler2D texture1;
//     uniform sampler2D texture2;
//     uniform sampler2D texture3;
//     uniform sampler2D texture4;
//     uniform float imageScale;

//     // Color
//     uniform vec3 colorBG;

//     varying vec2 vUv;

//     float scaleCenter = 0.5;

//     bool isHighlighted(float index) {
//         for (int i = 0; i < 8; i++) {
//             if (index == 0.) {
//                 return false;
//             } else if (highlightedIndexes[i] == index) {
//                 return true;
//             }
//         }

//         return false;
//     }

//     void main() {
//         // Ajusta las coordenadas UV para repetir la imagen como un patrón
//         vec2 repeatUv = vec2(vUv.x * cols, vUv.y * cols * (resolution.y / resolution.x));
//         vec2 repeatUvFinal = vec2(vUv.x * cols, vUv.y * cols * (resolutionFinal.y / resolutionFinal.x));

//         // Calcula el índice de tile basado en las coordenadas UV
//         vec2 tileIndex = floor(repeatUv);
//         float colIndex = mod(tileIndex.x, cols); // Índice de columna
//         float rowIndex = mod(tileIndex.y, ceil(resolution.y / (resolution.x / cols))); // Índice de fila
//         float index = colIndex + rowIndex * cols; // Índice único basado en columna y fila

//         // Número máximo de elementos en la pantalla
//         float maxCols = cols;
//         float maxRows = ceil(resolution.y / (resolution.x / cols));
//         float maxElements = maxCols * maxRows;
//         float index0 = maxElements - index;

//         // Aplica la función fract para obtener solo la parte fraccional de las coordenadas UV
//         vec2 patternUv = fract(repeatUv);
//         vec2 patternUvFinal = fract(repeatUvFinal);

//         // Ajusta las coordenadas de textura según la escala de imagen
//         vec2 textureUVScale = (patternUv - scaleCenter) * imageScale + scaleCenter;
//         vec2 textureUVScaleFinal = (patternUvFinal - scaleCenter) * imageScale + scaleCenter;

//         // Gets texture pixel color
//         vec4 textureColor = texture2D(texture1, textureUVScale);
//         vec4 textureColor2 = texture2D(texture2, textureUVScale);
//         vec4 textureColor3 = texture2D(texture3, textureUVScale);
//         vec4 textureColor4 = texture2D(texture4, textureUVScaleFinal);
//         // vec4 textureColorAlt = texture2D(texture1Alt, textureUVScale);

//         vec4 finalTexture;
//         vec4 finalBG = vec4(colorBG, 1.);

//         gl_FragColor = finalBG; // Oculta el tile

//         // Single word animation
//         // if (step == 0.0) {
//         //     if (index0 == showIndex) {
//         //         finalTexture = vec4(finalTexture.rgb, finalTexture.a * opacity);
//         //     } else {
//         //         finalTexture = finalBG; // Oculta el tile
//         //     }
//         // } else if (step == 1.0) {
//         // Even / Odd word animation
//         if (step == 0.0) {
//             if (mod(index + showIndexOffset, 2.) == 0.0) {
//                 finalTexture = vec4(textureColor.rgb, textureColor.a * opacity);
//             } else {
//                 // finalTexture = finalBG; // Oculta el tile;
//                 discard;
//             }
//         // Same row movement
//         // } else if (step == 1.0) {
//         //     if (mod(rowIndex + showIndexOffset, cols) == 0.0) {
//         //         finalTexture = vec4(finalTexture.rgb, finalTexture.a * opacity);
//         //     } else {
//         //         finalTexture = finalBG; // Oculta el tile
//         //     }
//         // } else if (step == 2.0) {
//         // Row + desplazamiento animation
//         } else if (step == 1.0) {
//             if (mod(index0 + rowIndex + showIndexOffset, maxCols) == 0.0) {
//                 finalTexture = vec4(textureColor2.rgb, textureColor2.a * opacity);
//             } else {
//                 // finalTexture = finalBG; // Oculta el tile
//                 discard;
//             }
//         } else if (step == 2.0) {
//             // if (rowIndex == showRowIndex) {
//             if (mod(rowIndex, 3.) == mod(showRowIndex, 3.)) {
//                 finalTexture = vec4(textureColor3.rgb, textureColor3.a * opacity);
//             } else {
//                 // finalTexture = finalBG; // Oculta el tile
//                 discard;
//             }
//         } else if (step == 3.0) {
//             finalTexture = vec4(textureColor4.rgb, textureColor4.a * opacity);
//         } else if (step == 4.0) {
//             if (isHighlighted(index0)) {
//                 finalTexture = vec4(textureColor.rgb, textureColor.a * opacity);
//             } else {
//                 finalTexture = finalBG; // Oculta el tile
//                 discard;
//             }
//         } else {
//         //     finalTexture = finalBG; // Oculta el tile
//             discard;
//         }

//         // if (finalTexture.a == 0.) { finalTexture = finalBG; }
//         if (finalTexture.a == 0.) { discard; }

//         gl_FragColor = finalTexture;
//     }
// `;
